import "./App.css";

import * as React from "react";
import * as pdfJS from "pdfjs-dist/build/pdf.js";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

function App() {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [results, setResults] = React.useState([]);

  pdfJS.GlobalWorkerOptions.workerSrc =
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

  const getPdfContent = async (pdf) => {
    let content = "";

    for (let i = 1; i <= pdf._pdfInfo.numPages; i++) {
      const page = await pdf.getPage(i);
      content += await page.getTextContent().then((textContent) => {
        const textItems = textContent.items;
        let finalString = "";
        let prevTransform = 0;

        for (var j = 0; j < textItems.length; j++) {
          const item = textItems[j];

          if (prevTransform !== item.transform[5]) {
            if (prevTransform > 0) {
              finalString += "\n";
            }
            prevTransform = item.transform[5];
          }

          if (item.transform[5] !== 38.48058460776) {
            finalString += item.str + " ";
          }
        }

        return finalString;
      });
    }

    return content;
  };

  const parseContent = (content) => {
    const parts = content.split("Til: ");

    return parts
      .map((part) => {
        const response = {};
        const subParts = part.split("\n");

        for (let line = 0; line < subParts.length; line++) {
          const element = subParts[line].trim();

          if (element.startsWith("Luftfartøyets kjennetegn")) {
            response.reg = element
              .replace("Luftfartøyets kjennetegn", "")
              .trim();
          } else if (element.startsWith("Luftfartøyets klubbtilhørighet")) {
            response.club = element
              .replace("Luftfartøyets klubbtilhørighet", "")
              .trim();
          } else if (element.startsWith("For og etternavn (eier/bruker)")) {
            response.user = element
              .replace("For og etternavn (eier/bruker)", "")
              .trim();
          } else if (element.startsWith("Epost")) {
            response.email = element.replace("Epost", "").trim();
          } else if (element.startsWith("Telefon (helst mobil)")) {
            response.phone = element
              .replace("Telefon (helst mobil)", "")
              .trim();
          } else if (element.startsWith("Dato/tid:")) {
            response.date = element.replace("Dato/tid:", "").trim();
          } else if (element.startsWith("Tid brukt:")) {
            response.timeSpent = element.replace("Tid brukt:", "").trim();
          } else if (
            element.startsWith(
              "Er sportsflyet ditt fabrikk- eller hjemmebygget?"
            )
          ) {
            let value = [];
            for (
              let lookAhead = line + 1;
              lookAhead < subParts.length;
              lookAhead++
            ) {
              const nextElement = subParts[lookAhead].trim();
              if (nextElement === "Your Answer" || nextElement === "Svar")
                continue;
              if (nextElement.startsWith("Spørsmål 2")) break;

              value.push(nextElement);
            }

            response.q1 = value.join("\n");
          } else if (
            element.startsWith(
              "Er sportsflyet ditt modifisert eller reparert i forhold til sportsflyhåndboken utgave 8"
            )
          ) {
            let value = [];
            for (
              let lookAhead = line + 2;
              lookAhead < subParts.length;
              lookAhead++
            ) {
              const nextElement = subParts[lookAhead].trim();
              if (nextElement === "Your Answer" || nextElement === "Svar")
                continue;
              if (nextElement.startsWith("Spørsmål 3")) break;

              value.push(nextElement);
            }

            response.q2 = value.join("\n");
          } else if (
            element.startsWith(
              "Dersom sportsflyet ditt modifisert eller reparert til nivå 0, 1 eller 2, beskriv kortfattet"
            )
          ) {
            let value = [];
            for (
              let lookAhead = line + 5;
              lookAhead < subParts.length;
              lookAhead++
            ) {
              const nextElement = subParts[lookAhead].trim();
              if (nextElement === "Your Answer" || nextElement === "Svar")
                continue;
              if (nextElement.startsWith("Spørsmål 4")) break;

              value.push(nextElement);
            }

            response.q3 = value.join("\n");
          } else if (
            element.startsWith(
              "Utfra ovenstående, er det i dine øyne naturlig at ditt sportsfly plasseres i"
            )
          ) {
            let value = [];
            for (
              let lookAhead = line + 2;
              lookAhead < subParts.length;
              lookAhead++
            ) {
              const nextElement = subParts[lookAhead].trim();
              if (nextElement === "Your Answer" || nextElement === "Svar")
                continue;
              if (nextElement.startsWith("Spørsmål 5")) break;

              value.push(nextElement);
            }

            response.q4 = value.join("\n");
          } else if (
            element.startsWith("Andre ting som kan være relevant å nevne")
          ) {
            let value = [];
            for (
              let lookAhead = line + 2;
              lookAhead < subParts.length;
              lookAhead++
            ) {
              const nextElement = subParts[lookAhead].trim();
              if (nextElement === "Your Answer" || nextElement === "Svar")
                continue;
              if (nextElement.startsWith("kristian.fahlstrom@gmail.com")) break;

              value.push(nextElement);
            }

            response.q5 = value.join("\n");
          }
        }

        if (!response?.reg) {
          console.log(subParts);
        }

        return !response?.reg ? undefined : response;
      })
      ?.filter((r) => r !== undefined);
  };

  const handleCapture = async ({ target }) => {
    setSelectedFile(target.files[0]);

    let fileReader = new FileReader();
    fileReader.onload = function () {
      //Step 4:turn array buffer into typed array
      var typedarray = new Uint8Array(this.result);

      const pdfLoader = pdfJS.getDocument(typedarray);
      pdfLoader.promise.then(async (pdf) => {
        let content = await getPdfContent(pdf);
        const newResults = parseContent(content);

        setResults([...results, ...newResults]);
      });
    };

    //Step 3:Read the file as ArrayBuffer
    fileReader.readAsArrayBuffer(target.files[0]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="xl">
        <Typography variant="h2" component="h1" gutterBottom>
          Importeringsverktøy for PDF-spørreskjema
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Bruksanvisning
        </Typography>
        <ol>
          <li>Legg til så mange PDF-er du trenger</li>
          <li>Marker teksten i tabellen under, kopier (Ctrl+C)</li>
          <li>Lim inn i Excel</li>
        </ol>
        <Typography variant="body1">
          <i>
            Alt gjøres lokalt i din nettleser, resultatene blir aldri lastet opp
            eller sendt noen sted.
          </i>
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ marginTop: 3 }}
        >
          Legg til PDF-fil
        </Typography>
        <Paper sx={{ padding: 2 }}>
          <input
            accept="application/pdf"
            id="pdfFile"
            type="file"
            onChange={handleCapture}
            style={{ display: "none" }}
          />
          <Tooltip title="Velg PDF">
            <label htmlFor="pdfFile">
              <IconButton
                color="primary"
                aria-label="upload PDF"
                component="span"
              >
                <PictureAsPdfIcon fontSize="large" />
              </IconButton>
            </label>
          </Tooltip>
          <label>{selectedFile ? selectedFile.name : "Velg PDF"}</label>. . .
        </Paper>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ marginTop: 3 }}
        >
          Resultater
        </Typography>
        <Typography variant="body2">
          {results.length} {results.length === 1 ? "resultat" : "resultater"}
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Luftfartøyets kjennetegn
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Luftfartøyets klubbtilhørighet
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  For og etternavn (eier/bruker)
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Epost</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Telefon (helst mobil)
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Dato/tid</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Tid brukt</TableCell>
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  title="Er sportsflyet ditt fabrikk- eller hjemmebygget?"
                >
                  Spørsmål 1
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  title="Er sportsflyet ditt modifisert eller reparert i forhold til sportsflyhåndboken utgave 8 
kapittel 5.4.1."
                >
                  Spørsmål 2
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  title="Dersom sportsflyet ditt modifisert eller reparert til nivå 0, 1 eller 2, beskriv kortfattet 
noen linjer om hver modifikasjon eller reparasjon som er utført om en eller begge disse 
punktene: 
1 - Motor og propell (1000 tegn). 
2 - Skrog og kontrollsystem (1000 tegn"
                >
                  Spørsmål 3
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  title="Utfra ovenstående, er det i dine øyne naturlig at ditt sportsfly plasseres i 
Experimentalklassen?"
                >
                  Spørsmål 4
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  title="Andre ting som kan være relevant å nevne."
                >
                  Spørsmål 5
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((result, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{result?.reg}</TableCell>
                  <TableCell>{result?.club}</TableCell>
                  <TableCell>{result?.user}</TableCell>
                  <TableCell>{result?.email}</TableCell>
                  <TableCell>{result?.phone}</TableCell>
                  <TableCell>{result?.date}</TableCell>
                  <TableCell>{result?.timeSpent}</TableCell>
                  <TableCell>{result?.q1}</TableCell>
                  <TableCell>{result?.q2}</TableCell>
                  <TableCell>{result?.q3}</TableCell>
                  <TableCell>{result?.q4}</TableCell>
                  <TableCell>{result?.q5}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="body1" fontSize={12}>
            A <a href="https://limanovember.aero/">LimaNovember.Aero</a> Tool
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default App;
